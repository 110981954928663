import React from 'react';

import Logo from '../logo';
import styles from './footer.module.css';

const Footer = () => (
  <div className={styles.footerWrapper}>
    <footer className={styles.footer}>
      <div className={styles.listsWrapper}>
        <ul className={styles.list}>
          <li>
            <a target="_blank" rel="noreferrer" href={'/aff-program'}>
              Become our affiliate
            </a>
          </li>
          <li>
            e-mail:{' '}
            <a href={'mailto:info@mindworxacademy.com'}>
              info@mindworxacademy.com
            </a>
          </li>
        </ul>

        <ul className={styles.socialList}>
          <li>
            <a
              href="https://www.facebook.com/Mindworx-Academy-472812813233182"
              target="_blank"
              rel="noreferrer"
            >
              Facebook
            </a>
          </li>
          <li>
            <a
              href="https://www.instagram.com/mindworx_academy/ "
              target="_blank"
              rel="noreferrer"
            >
              Instagram
            </a>
          </li>
          <li>
            <a
              href="https://www.linkedin.com/company/mindworxacademy/"
              target="_blank"
              rel="noreferrer"
            >
              LinkedIn
            </a>
          </li>
        </ul>

        <ul className={styles.listBottom}>
          <li>
            <a href="/privacy-policy">Privacy Policy</a>
          </li>
          <li>
            <a href="/terms-of-use">Terms and Conditions</a>
          </li>
        </ul>
      </div>

      <a href="/#" title="MINDWORX" className={styles.logo} />
      <div>
        <Logo style={{ width: 'auto' }} type="white" />
      </div>

      <div className={styles.footerCopy}>
        ©2019 - 2020 MINDWORX Academy. All rights reserved.
      </div>
    </footer>
  </div>
);

export default Footer;
